import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollIndicator from './components/ScrollIndicator';
import ScrollToTopRoute from './components/ScrollTopRoute';
import Home4 from './pages/Home4';


function App() {
    return (
		<>
			<Router>
				<ScrollToTopRoute />
				<Switch>
					<Route path="*">
						<Home4 />
					</Route>
				</Switch>
			</Router>
			<ScrollIndicator />
		</>
	)
}

export default App;
