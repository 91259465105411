import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Media } from "reactstrap"
import FeatherIcon from "feather-icons-react"
import styled from "styled-components"

//Import Footer link
import FooterLinks from "./footer-links"

const FooterItem = styled.div`
	.footer {
		background-color: #00336d;
		color: rgba(255, 255, 255, 0.5);
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.footer-list-title {
		font-size: 16px;
		color: #ffffff;
	}

	.footer-list-menu li a {
		display: inline-block;
		font-size: 15px;
		color: rgba(255, 255, 255, 0.5);
		margin-bottom: 14px;
		-webkit-transition: all 0.4s;
		transition: all 0.4s;
	}

	.footer-list-menu li a:hover {
		color: rgba(255, 255, 255, 0.9);
	}

	i {
		color: #ffffff;
		padding: 0 30px 0 0;
	}

	a {
		display: inline-block;
		font-size: 15px;
		color: rgba(255, 255, 255, 0.5);
		margin-bottom: 5px;
		-webkit-transition: all 0.4s;
		transition: all 0.4s;
	}

	a:hover {
		color: #ffffff;
	}

	.media {
		display: flex;
		align-items: center;
	}
`

class Footer extends Component {
	state = {
		links1: [
			{ link: "#", title: "About Us" },
			{ link: "#", title: "Media & Press" },
			{ link: "#", title: "Career" },
			{ link: "#", title: "Blog" },
		],
		links2: [
			{ link: "#", title: "Pricing" },
			{ link: "#", title: "For Marketing" },
			{ link: "#", title: "For CEOs" },
			{ link: "#", title: "For Agencies" },
			{ link: "#", title: "Our Apps" },
		],
	}
	render() {
		return (
			<React.Fragment>
				<FooterItem id="contact">
					<footer className="footer footer-wrap px-4 py-4 d-lg-block">
						<Container>
							<Row>
								<Col sm={12} lg={3}>
									<div className="flex-row align-content-start py-4">
										<h5 className="mb-4 footer-list-title">
											About Usizo Consultancy & Services
										</h5>
										<p>
											Usizo Consultancy & Services employs
											a balanced, multi-faceted and
											holistic approach in providing
											solutions that optimise our client’s
											resources and investments.{" "}
										</p>
									</div>
								</Col>

								<Col>
									<div className="flex-row justify-content-center py-4">
										<h5 className="mb-4 footer-list-title">
											Physical Address
										</h5>
										<p className="text-center">
											70720 Pharama Section <br />
											Moses Kotane <br />
											Ledig <br />
											Rustenburg{" "}
										</p>
									</div>
								</Col>
								<Col>
									<div className="flex-row justify-content-center py-4">
										<h5 className="mb-4 footer-list-title">
											Postal Address
										</h5>
										<p className="text-center">
											2055 African Wanderers Street
											<br />
											Extension 3
											<br />
											Nellmapius
											<br />
											Pretoria, 0122{" "}
										</p>
									</div>
								</Col>

								<Col sm={12} md={4} className="d-flex justify-content-around">
									<div className="py-4">
										<h5 className="text-center mb-4 footer-list-title">
											Contact
										</h5>

										<div>
											<div className="flex-row mb-4 media">
												<i>
													<FeatherIcon
														icon="mail"
														className="icon-dual-light icons-sm mt-1 mr-2"
													/>
												</i>
												<Media body>
													<p>
														<a
															href="mailto: info@usizoconsulting.co.za?subject = Enquiry"
															target="_blank"
															rel="noopener noreferrer"
														>
															info@usizoconsulting.co.za
														</a>
														<br />
														<a
															href="mailto: sxdails@usizoconsulting.co.za?subject = Enquiry"
															target="_blank"
															rel="noopener noreferrer"
														>
															sxdails@usizoconsulting.co.za
														</a>
														<br />
														<a
															href="mailto: sxdails@gmail.com?subject = Enquiry"
															target="_blank"
															rel="noopener noreferrer"
														>
															sxdails@gmail.com
														</a>
													</p>
												</Media>
											</div>
											<Media className="flex-row mt-4 media">
												<i>
													<FeatherIcon
														icon="phone"
														className="icon-dual-light icons-sm mt-1 mr-2"
													/>
												</i>
												<Media body>
													<a href="tel: +27843445668">
														Director : 084 344 5668
													</a>
													<br />
													<a href="tel: +27798685708">
														Manager : 079 868 5708
													</a>
												</Media>
											</Media>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</footer>
					{/* Render footer links */}
					<FooterLinks />
				</FooterItem>
			</React.Fragment>
		)
	}
}

export default Footer
