import React from "react"
import { Link } from "react-router-dom"
import logo from "../../assets/img/logo/color.png"
import MobileMenu from "../MobileMenu"
import {
	Navbar,
	Nav,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
	Container,
	Collapse,
	Button,
} from "reactstrap"
import styled from "styled-components"

const CustomNav = styled.div`
	
	.navbar-custom {
		filter: drop-shadow(0 2px 5px #00000020);
		padding: 0px 0px;
		width: 100%;
		z-index: 99999;
		margin-bottom: 0px;
		transition: all 0.5s ease-in-out;

		.navbar-nav {
			justify-content: flex-end;
			.nav-item {
				.nav-link {
					line-height: 26px;
					color: rgba(orange, 0.8);
					transition: all 0.4s;
					background-color: transparent !important;
					padding: 6px 10px;
					margin: 0 7px;
					&:hover,
					&:active {
						color: blue;
					}
				}
				&.active {
					.nav-link {
						color: #ffa800;
					}
				}
			}
		}

		.navbar-btn {
			margin-left: 10px;
			color: orange;
			background: rgba(orange, 0.2);
			border-color: transparent;
			box-shadow: none;

			&:hover {
				color: "white";
				box-shadow: 0px 16px 31px -16px orange;
				background: darken(orange, 4%);
				border-color: darken(orange, 4%);
			}
		}
	}
`

const MainNavBar = (props) => {
	// Store all Navigationbar IDs into TargetID variable (for the Scrollspy)

	// let targetId = props.navItems.map((item) => {
	// 	return item.idx
	// })

	return (
		<CustomNav>
			<Navbar
				fixed="top"
				expand="lg"
				className="navbar-custom"
				style={{
					backgroundColor: "white",
					width: "100%",
					justifyContent: "space-between",
					display: "inline",
				}}
			>
				<Container className="d-flex flex-row align-items-center">
					<div className="col-lg-2 col-5 col-md-4 ">
						<NavbarBrand>
							<img src={logo} className="logo" height="50px" />
						</NavbarBrand>
					</div>
					<div className="col-lg-10 pl-lg-3 d-none d-lg-flex justify-content-end">
						<Nav
							navbar
							className="navbar-nav"
							id="mySidenav"
							style={{
								padding: "20px 0px",
								width: "100%",
								zIndex: "999",
								marginBottom: "0px",
								transition: "all 0.5s ease-in-out",
							}}
						>
							<NavItem className="active">
								<NavLink>Home</NavLink>
							</NavItem>
							<NavItem>
								<NavLink>Home</NavLink>
							</NavItem>
							<NavItem>
								<NavLink>Home</NavLink>
							</NavItem>
							<NavItem>
								<NavLink>Home</NavLink>
							</NavItem>
						</Nav>
					</div>
					<div className="col-6 col-md-8 col-md-text-right d-inline-flex align-items-center">
						<div className="col mobile-menu-bar">
							<MobileMenu />
						</div>
					</div>
				</Container>
			</Navbar>
		</CustomNav>
	)
}

export default MainNavBar
