import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import ModalVideo from 'react-modal-video';
import icon1 from '../../assets/img/home2/icon/1.png';
import icon2 from '../../assets/img/home2/icon/2.png';
import videoBg from '../../assets/img/home2/video_bg.jpg';
import servicesData from '../data/services';
import ServiceItem from './ServiceItem';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 25px 50px;
	margin-top: 20px;
	gap: 20px;
	height: 80px;
	background: #ffa800;
	flex: none;
	order: 0;
	flex-grow: 0;
	justify-content: center;

	.button-text {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: #ffffff;
	}
	&:hover {
		background: #fcbd44;
	}
`

function CoreFeature() {

	const scrollTo = (id) => {
		const comp = document.getElementById(id)
		comp.scrollIntoView({ behaviour: "smooth" })
	}
    
    return (
		<>
			<section
				className="video-fetaures-wrapper section-bg section-padding"
				id="services"
			>
				<div className="container">
					<div className="features-list">
						<div className="row">
							<div className="section-title col-xl-4 col-12 my-auto">
								<p>Core Features</p>
								<h1>
									Our <br />
									Services
								</h1>
							</div>
							{servicesData.map((service) => {
								return (
									<ServiceItem
										title={service.title}
										items={service.items}
										icon={service.icon}
									/>
								)
							})}

							<div className="col-xl-4 col-12 my-auto">
								<a>
									<ButtonWrapper
										onClick={() => scrollTo("contact")}
									>
										<div className="button-text">
											Get in touch
										</div>
									</ButtonWrapper>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default CoreFeature;
