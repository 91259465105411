import React from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const MenuContent = () => (
	<div className="mobile-menu-bottom">
		<ul>
			<li className="bottom-item">
				<AiOutlineMail className="icon" />
				info@usizoconsulting.co.za
			</li>
			<li className="bottom-item">
				<AiOutlinePhone className="icon" />
				079 868 5708
				<br />
				084 344 5668
			</li>
		</ul>
		<div className="bottom-btn">
			<Link onClick={( )=> document.getElementById("contact").scrollIntoView({behavior:"smooth"}) } className="d-btn theme-btn d-block text-white">
				Contact Us
			</Link>
		</div>
	</div>
)

export default MenuContent;
