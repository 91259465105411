import React, { useState } from "react"
import { FaComments } from "react-icons/fa"
import bg from "../../assets/img/usizo/bg6.jpeg"
import img from "../../assets/img/subscribe_left_bg.jpg"
import logo from "../../assets/img/logo/white.png"
import styled from "styled-components"

const LogoImage = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px;
	gap: 15px;

	div {
		width: 10px;
		height: 150px;
		background: #ffa800;
	}

	img {
		height: 50%;
		max-height: 250px;
		min-height: 150px;
		min-width: 50%;
		max-width: 100%
	}

`

function Hero4() {

	return (
		<section
			className="subscribe-box-wrapper d-flex justify-content-around text-white bg-overlay bg-cover"
			style={{
				backgroundImage: `url(${bg})`,
				alignItems: "center",
				flexDirection: "row",
			}}
			id="home"
		>
			<div>
				<div>
					<div
						className="col-12 "
						style={{
							paddingTop: "200px",
							paddingBottom: "200px",
						}}
					>
						<div className="cta-contents text-center text-xl-left">
							<LogoImage>
								<div></div>
								<img src={logo} />
							</LogoImage>
							{/* <h1>Usizo Consultancy and services</h1> */}
							<p>
								The highest level quality services at fair and
								competitive prices.
							</p>
						</div>
					</div>
					{/* <div
						className=" d-none d-xl-flex col-xl-4 bg-cover"
						style={{
							backgroundColor: "#00336D",
						}}
					>
						<div className="row">
							<div className="col-6">
								<p
									style={{
										color: "white",
									}}
								>
									Name of thing
								</p>
							</div>

							<div className="col-6">
								<p
									style={{
										color: "white",
									}}
								>
									Name of thing
								</p>
							</div>

							<div className="col-6">
								<p
									style={{
										color: "white",
									}}
								>
									Name of thing
								</p>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</section>
	)
}

export default Hero4
