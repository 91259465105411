import React from 'react';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

const menuData = [
	{
		title: "Home",
		path: "/",
		id: "home",
	},

	{
		title: "About",
		id: "about",
	},

	{
		title: "Services",
		id: "services",
	},

	{
		title: "Contact",
		id: "contact",
	},
]

export default menuData;
