import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

function FaqAccordion({ question, answer, year}) {
    
    return (
		<>
			<Accordion allowZeroExpanded>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div className="col-3 mx-2 d-inline" style={{color:"grey", fontWeight:"normal"}}>{year}</div>{" "}
							{question}
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>{answer}</AccordionItemPanel>
				</AccordionItem>
			</Accordion>
		</>
	)
}

export default FaqAccordion;
