import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import logo from '../../assets/img/logo/white.png'

class FooterLinks extends Component {
    render() {
        return (
			<React.Fragment>
				<section className="bg-secondary py-3">
					<Container>
						<Row>
								<div className="col">
									<Link to="#">
										<img src={logo} alt="" height="45" />
									</Link>
								</div>
								<div className="col d-flex" style={{
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}>
									<p className="copyright-desc text-white mb-0">
										{new Date().getFullYear()} © Usizo
										Consultancy and services
									</p>
								</div>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		)
    }
}

export default FooterLinks;