const servicesData = [
	{
		title: "Water",
		icon: "ic:outline-water-drop",
		items: [
			"Bulk water supply",
			"Water distribution networks ",
			"Storage reservoirs & pumping stations",
		],
	},

	{
		title: "Roads and Earthworks",
		icon: "fluent:road-cone-16-regular",
		items: [
			"Construction of rural and urban roads",
			"Rehabilitation of roads",
			"Bulk earthworks",
		],
	},

	{
		title: "Stormwater Drainage",
		icon: "bi:cloud-lightning-rain",
		items: ["Canals", "Piped storm-water systems", "Attenuation ponds"],
	},

	{
		title: "Professional Services",
		icon: "ic:round-work-outline",
		items: [
			"Consulting",
			"Quantity Surveying",
			"Storage reservoirs & pumping",
		],
	},

	{
		title: "Plant Hire",
		icon: "mdi:bulldozer",
		items: ["Heavy duty plants", "Plant maintenance"],
	},

	{
		title: "Sanitation",
		icon: "akar-icons:trash-can",
		items: [
			"Outfall sewer pipelines",
			"Sewerage reticulation networks",
			"Sewerage pumping stations",
			"Rising mains",
			"Sewerage treatment works",
		],
	},
	{
		title: "General Building",
		icon: "mdi:account-hard-hat-outline",
		items: [
			"Extensions",
			"New building",
			"Complete commercial & residential",
			"Refurbishments",
			"Plumbing, electrical & tiling work",
		],
	},
]

export default servicesData