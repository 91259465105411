import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import img from '../../assets/img/CIDB_Logo_white.svg';

function Cta2() {
    return (
		<div className="cta-funfact-wrap">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 text-white">
						<div className="fun-fact d-flex align-items-center justify-content-around">
							<div className="col justify-content-center">
								<img src={img} alt="" />
							</div>
							{/* <div className="col ">
								<span>Level 1</span>
							</div> */}
							{/* <span></span>
							 <p>
								Registration no: <br /> 10105733
							</p> */}
						</div>
					</div>
					<div className="col-lg-8 p-lg-0">
						<div className="cta-wrap d-flex justify-content-center justify-content-md-around align-items-center">
							<h2 className="text-white">
								PE | GB | CE | SH
							</h2>
							{/* <a href="contact.html" className="">
								Get A Quote <BsArrowRight />
							</a> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cta2;
