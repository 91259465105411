import React from "react"
import { BsArrowRight } from "react-icons/bs"
import { Link } from "react-router-dom"
import Cta2 from "../Cta2"
import aboutUsizoData from "./aboutUsizoData"

function AboutUsizo() {
	return (
		<section className="about-wrapper section-padding pb-0" id="about">
			<div className="container">
				<div className="row align-center">
					<div
						className="col-lg-4 col-12 d-none d-lg-block"
						style={{
							backgroundImage: aboutUsizoData.img,
						}}
					>
						<div className="promo-img">
							<img
								src={aboutUsizoData.img}
								alt=""
								style={{ objectFit: "cover", height: "650px" }}
							/>
						</div>
					</div>
					<div className="col-lg-8 pl-xl-5 col-12">
						<div className="block-contents ml-40">
							<span>About Our Company</span>
							<h1>{aboutUsizoData.heading}</h1>
							{/* <h4>
                                {aboutUsizoData.quote}
                                <span>
                                    <b>{aboutUsizoData.author}</b> {aboutUsizoData.position}
                                </span>
                            </h4> */}
							<br />
							<p>{aboutUsizoData.text}</p>
							<br />
						</div>
					</div>
				</div>
			</div>
			<Cta2 />
		</section>
	)
}

export default AboutUsizo
