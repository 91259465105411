import React from 'react'
import icon1 from "../../assets/img/home2/icon/1.png"
import { Icon } from "@iconify/react"

const ServiceItem = (props) => {
    const {title, icon, items} = props
    
  return (
		<div className="single-item col-xl-4 col-12 mx-auto">
			<div className="icon">
				<Icon
					icon={icon}
					style={{ fontSize: "40px", color: "#FFA800" }}
				/>
			</div>
			<div className="content d-flex-column d-xl-block">
				<h3>{title}</h3>
				<p>
					<ul style={{ listStyleType: "circle" }} className="d-block">
						{items.map((item) => {
							return <li>{item}</li>
						})}
					</ul>
				</p>
			</div>
		</div>
  )
}

export default ServiceItem