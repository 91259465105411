import img1 from '../../assets/img/usizo/bg4.jpeg';
import img2 from "../../assets/img/usizo/bg3.jpeg"

const aboutUsizoData = {
	img: img1,
	heading:
		"Committed to serving our customers with the highest level of quality services",
	text: "Usizo Consultancy & services is a privately owned enterprise started by Nthabiseng Dails. The company was established in 2016, doing Civil and Building Construction. Usizo Consultancy & services (PTY) Ltd established itself as a multifaceted company with proven capabilities over a wide range of	different	technical	fields	in	the	construction, Plant hire and Professional services which include Quantity survey and consulting since 2016. Usizo Consultancy & services has been trading for more than six (6) years in Building & Civil Construction. We are the master when it comes to Construction and Plant Hire. The company consists of members who have worked under well- developed consulting companies and government sectors on major projects and gained a substantial experience.",
}

export default aboutUsizoData;
