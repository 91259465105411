import React from 'react';
import MainMenu3 from './MainMenu3';
import MainNavBar from './NavbarUsizo';
import Topbar3 from './Topbar3';

function Header3() {
    return (
        <>
            {/* <Topbar3 /> */}

            <MainMenu3 />

            {/* <MainNavBar navItems={
                {navItems: [
                { id: 1, idnm: "home", navheading: "Home" },
                { id: 2, idnm: "about", navheading: "About Us" },
                { id: 3, idnm: "services", navheading: "Services" },
                { id: 4, idnm: "contact", navheading: "Contact Us" }
            ]}
            }/> */}
        </>
    );
}

export default Header3;
