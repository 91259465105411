import React from 'react';
import man1 from '../../assets/img/man1.png';
import bgImg from '../../assets/img/map_pattern.png';
import FaqAccordion from '../FaqAccordion';

function Faq() {
    return (
		<section className="faq-section section-padding">
			<div className="container">
				<div className="row">
					<div className="col-xl-5 col-12">
						<div className="content-block section-title">
							<p style={{ color: "#FFA800" }}>Recent Successes</p>
							<h1 style={{ color: "#00336D" }}>Past Projects</h1>
						</div>
					</div>
					<div className="col-xl-6 col-12 mt-4 mt-xl-0">
						<div className="faq-content">
							<FaqAccordion
								year="2013 - 2014"
								question="Sub-contracting for Quatrokor cc"
								answer="Pilanesberg Platinum mine- Weight bridge and concrete retaining walls"
							/>
							<FaqAccordion
								year="2014 - 2017"
								question="Sub-contracting for Bothlabatsatsi"
								answer="Potchestroom and Taung- Building community hall"
							/>
							<FaqAccordion
								year="2017 - 2019"
								question="Sub-contracting for Batlhaphing Investment Holdings"
								answer="Platform and plant hire"
							/>
							<FaqAccordion
								year="2019 - 2020"
								question="Sub-contracting for Tct civil & construction"
								answer="Concrete works"
							/>
							<FaqAccordion
								year="2021 - current"
								question="Contractor for bakubung platinum mine"
								answer=""
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Faq;
