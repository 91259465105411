import React from "react"
import { Link } from "react-router-dom"
import logo from "../../assets/img/logo/color.png"
import MobileMenu from "../MobileMenu"
import ScrollspyNav from "react-scrollspy-nav"

function MainMenu3() {

	const scrollTo = (id) => {
		const comp = document.getElementById(id)
		comp.scrollIntoView({ behaviour: "smooth" })
	}

	return (
		<header className="header-wrap header-3">
			<div className="container py-2">
				<div className="row align-items-center justify-content-between">
					<div className="col-lg-2 col-5 col-md-4">
						<div className="logo">
							<Link to="#">
								<img src={logo} alt="logo" />
							</Link>
						</div>
					</div>
					<div className="col-lg-10 col-md-6 d-none d-lg-flex pl-lg-3 justify-content-end">
						<div className="main-menu">
							<ul className="justify-content-end">
								<li onClick={() => scrollTo("home")}>
									<Link>Home</Link>
								</li>
								<li>
									<Link onClick={() => scrollTo("about")}>
										About
									</Link>
								</li>

								<li>
									<Link onClick={() => scrollTo("services")}>
										Services
									</Link>
								</li>

								<li>
									<Link onClick={() => scrollTo("contact")}>
										Contact
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-4 col-xl-3 col-6 col-md-5 text-right d-lg-none align-items-center">
						{/* <Link to="/contact" className="theme-btn theme-3">
                            Get A Quote
                        </Link> */}

						<div className="col mobile-menu-bar">
							<MobileMenu />
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default MainMenu3
