import React, { useEffect } from "react"
import AboutUsizo from "../components/AboutUsizo"
import Appointment from "../components/Appointment"
import Blog3 from "../components/Blog3"
import CoreFeature from "../components/CoreFeature"
import Faq from "../components/Faq"
import Faq2 from "../components/Faq2"
import Footer3 from "../components/Footer3"
import Header3 from "../components/Header3"
import Hero3 from "../components/Hero3"
import Hero4 from "../components/Hero4"
import Portfolio2 from "../components/Portfolio2"
import Process2 from "../components/Process2"
import Services3 from "../components/Services3"
import Sponsors2 from "../components/Sponsors2"
import Footer from "../components/UsizoFooter/footer"
import VideoFeatrued from "../components/VideoFeatured"

function Home4() {
	
	return (
		<>
			<Header3 />
			<Hero4 />
			<AboutUsizo />
			<CoreFeature/>
			<Faq />
			<Footer />
		</>
	)
}

export default Home4
